import { useCallback, useEffect } from "react";
import { type LoanApplicationInput } from "@jeff/lead-api/src/generated/lead-api-types";
import { type AppState, appState } from "@domain/app-state/csr";
import {
  LoanApplicationInputSchema,
  type LoanApplicationInputSchemaType,
} from "@domain/leads/types";
import { onError } from "@integration/bugsnag/csr";

type Props = {
  initialLoanApplicationInput: LoanApplicationInput | undefined;
};

export const useStoreLoanApplicationFlowData = (initialProps?: Props) => {
  const storeFlowUserData = useCallback(
    (inputs: LoanApplicationInput): AppState | undefined => {
      const safeInputs = LoanApplicationInputSchema.safeParse(inputs);

      if (!safeInputs.success) {
        onError(
          new Error(
            `useStoreLoanApplicationFlowData: ${safeInputs.error.message};
            inputs: ${JSON.stringify(inputs)}`,
          ),
        );

        return;
      }

      const newAppState = appState().setState((prev) => {
        return {
          ...prev,
          loanApplicationFlowData: {
            ...prev.loanApplicationFlowData,
            ...safeInputs.data,
          },
        };
      });

      return newAppState;
    },
    [],
  );

  const getUserFlowData =
    useCallback((): LoanApplicationInputSchemaType | null => {
      const userData = appState().getState().loanApplicationFlowData;
      return userData ?? null;
    }, []);

  const resetFlowUserData = useCallback(() => {
    const userData = appState().setState((prev) => ({
      ...prev,
      loanApplicationFlowData: undefined,
    }));

    return !!userData;
  }, []);

  useEffect(() => {
    if (!initialProps) {
      return;
    }

    if (initialProps.initialLoanApplicationInput) {
      storeFlowUserData(initialProps.initialLoanApplicationInput);
    } else {
      resetFlowUserData();
    }
  }, [initialProps, resetFlowUserData, storeFlowUserData]);

  return { storeFlowUserData, getUserFlowData, resetFlowUserData };
};
